import router from '@/Routes'

import FaqModel from '@/models/FaqModel'

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'checkbox',
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'id',
    label: 'Id',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'module',
    label: 'Module',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'module_actions',
    label: 'Actions',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'question',
    label: 'Question',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'tags',
    label: 'Tags',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center text-capitalize',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    thClass: 'align-middle text-center text-capitalize',
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
    thClass: 'align-middle text-center text-capitalize',
    type: 'date',
  },
  {
    key: 'action',
    label: '',
    filter: false,
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'action_list',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        action: (faq: FaqModel) => {
          router.push({ name: 'faq', params: { id: faq.id } })
        },
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        action: (faq: FaqModel) => {
          faq.delete()
        },
      },
      {
        icon: 'eye',
        title: 'Preview',
        event: 'preview',
      },
    ],
  },
]
