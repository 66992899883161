
import ViewModel from '@/models/ViewModel'
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import FaqModel from '@/models/FaqModel'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import faq_home_fields from './faq-home-fields'

@Component({
  components: {
    Widget,
    SearchInput,
    DataTable,
    IconAction,
  },
})
export default class FaqHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public records: number = 0

  public loading: boolean = false

  public preview: boolean = false

  public temp_faq: FaqModel | null = new FaqModel()

  public query: string[] = ['is:mine', 'is_not:completed']

  public selected: string[] = []

  public fieldFilters: any = {}

  public table_data: FaqModel[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get query_settings() {
    return true
  }

  public mounted() {
    this.fields = faq_home_fields
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return FaqModel.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public createFaq() {
    this.$router.push({ name: 'faq' })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }

  public previewFaq(e) {
    this.temp_faq = e
    this.preview = true
  }
}
